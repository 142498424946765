<template>
  <div class="inline-block">
    <color-text-btn size="medium" type="primary" @click="showDialog">
      <slot>
        修改
      </slot>
    </color-text-btn>
    <EForm
      v-if="dialogVisible"
      ref="form"
      title="修改授权"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :is-add="false"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import { commonEheaderMixin } from '@/mixins'
import EForm from './form'
export default {
  mixins: [commonEheaderMixin],
  components: { EForm }
}
</script>
